<template>
  <div class="h-full">
    <div
      v-if="canAddMessage"
      class="border border-dashed rounded-lg text-center mb-2"
      :class="{
        'border-primary border-opacity-30': addMessageButtonType === 'primary',
        'border-yellow-500 border-opacity-70 bg-yellow-500 bg-opacity-5': addMessageButtonType === 'orange',
      }"
    >
      <fw-button
        :type="`link-${addMessageButtonType}`"
        expanded
        size="sm"
        @click.native="openModal('add', newMessage)"
        >{{ addMessageButtonLabel || $t('addMessage') }}</fw-button
      >
    </div>
    <ContentLoader v-if="loading" />
    <div v-else class="h-full">
      <div v-if="messages && messages.length">
        <RecordMessage
          v-for="message in messages"
          :key="message.key"
          :with-leading="withMessageLeading"
          :message="message"
          :show-public-private-toggle="showPublicPrivateToggle"
          :force-private-message="forcePrivateMessage"
          :hide-private-messages="forceHidePrivateMessages"
          :can-edit="loggedUser.key == message.user_key && showPublicPrivateToggle && message.can_edit"
          :user="users && users[message.user_key] ? users[message.user_key] : {}"
          :copy-files-label="copyFilesLabel"
          :allow-copy-files="allowCopyFiles"
          @edit="openModal('edit', $event)"
          @delete="deleteMessage($event)"
          @copy-files="copyFiles(message)"
        />
        <BlockPagination
          v-if="pagination.totalPages > 1 && !loading"
          :per-page="pagination.limit"
          :total="pagination.totalResults"
          :total-pages="pagination.totalPages"
          :current.sync="pagination.page"
          @page-changed="$emit('page-changed', $event)"
        />
      </div>
      <fw-panel-info v-else centered empty> {{ $t('noMessages') }}. </fw-panel-info>
    </div>
    <fw-modal :active.sync="isModalActive" boxed="sm" size="3xl" width="42rem" confirm-close @close="closeModal">
      <ModalMessage
        v-if="isModalActive"
        :message="activeMessage"
        :instructions="addMessageModalInstructions"
        :force-private-message="forcePrivateMessage"
        :show-public-private-toggle="showPublicPrivateToggle"
        :ignore-confirm-public-message="ignoreConfirmPublicMessage"
        @close="closeModal"
        @save="saveMessage"
      ></ModalMessage>
    </fw-modal>
  </div>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import ModalMessage from '@/fw-modules/fw-core-vue/ui/components/modals/ModalMessage'
import RecordMessage from '@/fw-modules/fw-core-vue/ui/components/records/RecordMessage'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    BlockPagination,
    ContentLoader,
    RecordMessage,
    ModalMessage,
  },

  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 25,
        }
      },
    },
    withMessageLeading: {
      type: Boolean,
      default: false,
    },
    ignoreConfirmPublicMessage: {
      type: Boolean,
      default: false,
    },
    showPublicPrivateToggle: {
      type: Boolean,
      default: true,
    },
    forcePrivateMessage: {
      type: Boolean,
      default: false,
    },
    forceHidePrivateMessages: {
      type: Boolean,
      default: false,
    },
    defaultAddMessageIsPrivate: {
      type: Boolean,
      default: true,
    },
    canAddMessage: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    addMessageButtonLabel: {
      type: String,
      default: null,
    },
    addMessageModalInstructions: {
      type: String,
      default: null,
    },
    addMessageButtonType: {
      type: String,
      default: 'primary',
    },
    allowCopyFiles: {
      type: Boolean,
      default: false,
    },
    copyFilesLabel: {
      type: String,
      default: 'Copiar ficheiros',
    },
  },

  data() {
    return {
      activeModal: null,
      activeMessage: {},
      newMessage: { description: '', is_private: this.defaultAddMessageIsPrivate, files: [] },
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },

    isModalActive() {
      return Boolean(this.activeModal)
    },
  },

  methods: {
    copyFiles(message) {
      this.$emit('copy-files', message)
    },
    hasFileCopyListener() {
      return this.$listeners && this.$listeners['copyfiles']
    },
    deleteMessage(messageKey) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: this.$t('deleteMessageConfirm'),
        onConfirm: async () => {
          console.log('delete message :>> ', messageKey)
          this.$emit('delete-message', messageKey)
        },
        confirmText: this.$t('delete'),
        cancelText: this.$t('cancel'),
      })
    },

    async addMessage(newMessage) {
      console.log('add message :>> ', newMessage)
      this.$emit('add-message', newMessage)
      utils.sleep(250)
      this.closeModal()
    },

    async saveMessage(messageData) {
      if (this.activeModal == 'add') return this.addMessage(messageData)

      console.log('update message :>> ', messageData)
      this.$emit('update-message', messageData)
      utils.sleep(250)
      this.closeModal()
    },

    closeModal() {
      this.activeMessage = null
      this.activeModal = null
    },

    openModal(type, message) {
      console.log('openModal :>> ', type, message)
      this.activeMessage = message
      this.activeModal = type
    },
  },
}
</script>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full;
}
</style>

<i18n>
{
  "pt": {
    "noMessages": "Sem mensagens",
    "close": "Fechar",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
    "addMessage": "Adicionar nova mensagem"
  },
  "en": {
    "close": "Close",
    "cancel": "Cancel",
    "delete": "Delete",
    "noMessages": "No messages",
    "addMessage": "Add new message",
    "deleteMessageConfirm": "Are you sure you want to delete the message?",
    "addMessage": "Add new message"
  }
}
</i18n>
